const Home = () => {
  return (
    <div className="main-container">
      <section className="HeroSection">
        <div className="Introduction">
          <h1>WE'RE COMING SOON</h1>
          <h3>
            We are working very hard on the new version of our site. It will
            bring a lot of new features. Stay tuned!
          </h3>
        </div>
      </section>
    </div>
  );
};

export default Home;
