const Footer = () => {
  return (
    <div className="footer">
      <footer className="footerBar">
        <div className="Logo" style={{ color: "white" }}>
          Instapost
        </div>
        {/* <div className="FollowUs">
                    <span style={{fontWeight:"bold"}}>Follow us</span>
                    <div className="icons">
                        <i className="fab fa-twitter"></i>
                        <i className="fab fa-facebook-square"></i>
                        <i className="fab fa-instagram"></i>
                    </div>
                </div> */}
      </footer>
    </div>
  );
};

export default Footer;
