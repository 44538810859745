import { Link } from "react-router-dom";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const Header = () => {
  const history = useHistory();
  const [Menu, setMenu] = useState(false);
  function Clicked() {
    if (!Menu) {
      document.querySelector("#Navbar").style.transform = "";
      document
        .querySelector("#Navbar")
        .setAttribute(
          "style",
          "visibility:visible !important;width:100%; transform: translateY(2%) !important"
        );
      setMenu(true);
    } else {
      document.querySelector("#Navbar").style.transform = "translateY(-20%)";
      setTimeout(() => {
        document.querySelector("#Navbar").style.visibility = "hidden";
      }, 200);
      setMenu(false);
    }
  }
  function CloseMenu() {
    document.querySelector("#Navbar").style.transform = "translateY(-20%)";
    setTimeout(() => {
      document.querySelector("#Navbar").style.visibility = "hidden";
    }, 200);
    setMenu(false);
  }
  return (
    <div className="navbar">
      <header className="header">
        <div className="Logo" to="/" onClick={CloseMenu}>
          <Link to="/" onClick={CloseMenu}>
            Instapost
          </Link>
        </div>
        <nav className="nav" id="Navbar">
          <li>
            <a
              href="/Privacy"
              onClick={() => {
                history.push("/Privacy");
              }}
            >
              Privacy
            </a>
          </li>
          <li>
            <a
              href="/Term"
              onClick={() => {
                history.push("/Term");
              }}
            >
              ToS
            </a>
          </li>
        </nav>
        <i
          className="fas fa-bars H_Menu"
          style={{ fontSize: "1.6rem" }}
          onClick={Clicked}
        ></i>
      </header>
    </div>
  );
};

export default Header;
