const Home = () => {
  return (
    <div className="main-container">
      <section className="HeroSection">
        <div className="Introduction">
          <h1>Terms of Service</h1>
        </div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="tos_content">
                <ol>
                  <li>
                    Any person or organization or company that accesses the{" "}
                    <span>InstaPost</span> Service (simultaneously referred to
                    as "<span>You</span>"), accepts and agrees to be bound by
                    these Terms. <span>InstaPost</span> grants <span>You</span>{" "}
                    a limited, non-exclusive, non-transferrable, revocable
                    license to use the <span>InstaPost</span> Service (according
                    to your account type) in accordance with the Terms. The{" "}
                    <span>InstaPost</span> Service includes all unlimited{" "}
                    <span>InstaPost</span> Features embedded within the{" "}
                    <span>InstaPost</span> android app, <span>InstaPost</span>’
                    dashboard (my.
                    <span>InstaPost</span>.com) and <span>InstaPost</span>’
                    website (<span>InstaPost</span>.com).
                  </li>
                  <li>
                    <span>InstaPost</span> may change Terms of use of the{" "}
                    <span>InstaPost</span> Service from time to time, and{" "}
                    <span>InstaPost</span> will notify You of such changes via
                    email or by displaying a message when You use the{" "}
                    <span>InstaPost</span> Service. If You continue to use the{" "}
                    <span>InstaPost</span> Service upon receiving the message,
                    You are therefore in a state to accept and agree to the
                    change of Terms of use.
                  </li>
                  <li>
                    From time to time, <span>InstaPost</span> may add, modify or
                    remove all or part of the features or functions embedded
                    within the <span>InstaPost</span> Service. You can install
                    the latest version to receive benefits from those changes.{" "}
                    <span>InstaPost</span> may also decide to discontinue
                    providing all or any part of the Service at any time.
                    Referring to the Terms of Service available,{" "}
                    <span>InstaPost</span> does not guarantee the provision of
                    the Service to always be available either in its current
                    form or in any other form. In addition,{" "}
                    <span>InstaPost</span> also does not guarantee to provide
                    the full support, maintenance, or offer the continuity
                    related to the <span>InstaPost</span> Service or any Android
                    app or version thereof.
                  </li>
                  <li>
                    You agree to provide valid, accurate, current and complete
                    account information, and to maintain and promptly update
                    your account information to ensure it remains true,
                    accurate, current and complete.
                  </li>
                  <li>
                    <span>InstaPost</span> may cancel or suspend or terminate
                    your account at any time, under <span>InstaPost</span>' sole
                    discretion and policy, should You ever commit material
                    infringement or breach this provision or any other terms
                    applicable pertinent to the use of the{" "}
                    <span>InstaPost</span> Service.
                  </li>
                  <li>
                    If your account is canceled, then: (a) the account may be
                    deactivated or deleted; (b) all your rights in the terms of
                    use shall expire immediately; and (c) all your data and
                    content can be directly removed from the{" "}
                    <span>InstaPost</span> system. Content can not be recovered
                    after the account has been canceled. <span>InstaPost</span>{" "}
                    shall not be liable for any loss or damage as a result of
                    the cancellation of your account. It is of your
                    responsibility to ensure the replication of your content or
                    data prior to the cancelation.
                  </li>
                  <li>
                    You may request the cancellation of your account at any time
                    by contacting or emailing the Service Department (Customer
                    Support) from <span>InstaPost</span>.
                  </li>
                  <li>
                    The <span>InstaPost</span> Service is provided on an "as is"
                    and "as available" basis, and the usage of it falls under
                    your own risk. <span>InstaPost</span> will attempt to
                    promptly handle (during normal business hours) any technical
                    issues arising in connection with the <span>InstaPost</span>{" "}
                    Service provided.
                  </li>
                  <li>
                    <span>InstaPost</span> does not warrant that: (a) The{" "}
                    <span>InstaPost</span> Services will meet your specific
                    requirements; (b) The <span>InstaPost</span> Service shall
                    flow uninterrupted, timely, secure, or error-free; (c) The{" "}
                    <span>InstaPost</span> Service will be accurate or reliable;
                    (d) the quality of any product, service, information or
                    other material purchased or obtained by You through the{" "}
                    <span>InstaPost</span> Service will justify your
                    expectations; or (e) errors upon the provision of the{" "}
                    <span>InstaPost</span> Service will be fixed.
                  </li>
                  <li>
                    You acknowledge that <span>InstaPost</span> may use third
                    party suppliers to provide hardware, software, networking,
                    connectivity, storage, and other supporting technologies to
                    provide the Service. Any action and deeds of such suppliers
                    may fall beyond the <span>InstaPost</span>’ control, and{" "}
                    <span>InstaPost</span> shall not accept any liability for
                    any loss or damage occurred as a result of the actions or
                    deeds of such suppliers. For and on behalf of himself and
                    the supplier of such third parties involved within,{" "}
                    <span>InstaPost</span> excludes any warranties which may be
                    implied or otherwise applicable under other applicable laws
                    and regulations, to the extent permitted by applicable law.
                  </li>
                  <li>
                    To the maximum extent permitted by law,{" "}
                    <span>InstaPost</span> shall not be liable for any direct,
                    indirect, incidental, special, consequential or exemplary
                    damages, including but not restricted to the damages for
                    loss of profits, business, good intentions, usage, data or
                    other intangible loss (even if <span>InstaPost</span> has
                    been advised of the possibility of such damages), resulting
                    from: (a) the usage or inability to use the Service (in
                    whole or in some parts) or any technical malfunction; (b)
                    the cost of procuring replacement goods or services; (c)
                    restricted access to, or alteration of, your communications
                    or data; (d) any statement or action of any third party in
                    connection with the Service; or (e) any other problems in
                    regard to the service provided. In any case, the maximum
                    liability of <span>InstaPost</span> in connection with your
                    Terms of use or services usage is confined to the amount of
                    Usage Fee paid by You in the last 12 months.
                  </li>
                  <li>
                    You are responsible for all activities resulting from the
                    usage of the <span>InstaPost</span> Service through your
                    account, as well as for maintaining the security of your
                    account and password. <span>InstaPost</span> is not held
                    responsible for any loss or damage caused by the failure to
                    keep its username and password secure. You will indemnify{" "}
                    <span>InstaPost</span> against any and all liability,
                    action, claims, demands, fees, charges and expenses which
                    may be incurred or suffered by <span>InstaPost</span>{" "}
                    resulting from your use of the service through Your Account
                    or as a result of failure to comply with these Terms of use.
                  </li>
                  <li>
                    You are not allowed to: (a) use the <span>InstaPost</span>{" "}
                    Service in any unauthorized manner, for any unlawful or
                    fraudulent purpose, or in any manner inconsistent with these
                    Terms; (b) infringe the intellectual property rights of{" "}
                    <span>InstaPost</span> or any third party in connection with
                    your usage of the <span>InstaPost</span> Service; (c) send
                    harmful, offensive or inappropriate material in regard to
                    your usage of the <span>InstaPost</span> Service; (d) use
                    the <span>InstaPost</span> Service in a manner that could
                    damage, disable, overburden, destroy or compromise the
                    system or the security of <span>InstaPost</span> or
                    otherwise interfere with other Users; (e) collect or harvest
                    information or data from the <span>InstaPost</span> Service
                    or <span>InstaPost</span> system or attempt to decipher any
                    transmission to or from a server running{" "}
                    <span>InstaPost</span> Services; (f) disclose or distribute
                    information related to other Users of the{" "}
                    <span>InstaPost</span> Service to any third party, or use
                    other User information for marketing purposes unless with
                    the legitimate consent from the referred User; or (g) access
                    or register User login via bots or other automated methods.
                  </li>
                  <li>
                    You agree to use the <span>InstaPost</span> Service in a
                    reasonable manner. If <span>InstaPost</span> considers that
                    your usage of the <span>InstaPost</span> Service is not wise
                    or may result in a decline in the performance of the{" "}
                    <span>InstaPost</span> Service for You or any other User,{" "}
                    <span>InstaPost</span> may impose restrictions on your usage
                    of the <span>InstaPost</span> Service. Where possible,{" "}
                    <span>InstaPost</span> will give You 24 (twenty four) hours
                    of advance notice and request for your usage to be reduced
                    preceding any restrictions that will be applied henceforth.
                  </li>
                  <li>
                    You agree that <span>InstaPost</span> owns all intellectual
                    property rights confined throughout the{" "}
                    <span>InstaPost</span> Services. Therefore, You agree not to
                    violate or do anything that is inconsistent in regard to the
                    ownership stated. You are not allowed to publish or use any{" "}
                    <span>InstaPost</span> brand, brand or logo except with
                    prior written consent from <span>InstaPost</span>. You grant{" "}
                    <span>InstaPost</span> a royalty-free, irrevocable,
                    perpetual license to use (including for commercial purposes)
                    information and/or data collected by <span>InstaPost</span>{" "}
                    through your usage of the <span>InstaPost</span> Service,
                    provided that <span>InstaPost</span> collects or anonymizes
                    such information or data precedent to its usage. In addition
                    to this right, <span>InstaPost</span> does not claim any
                    intellectual property rights in connection with any
                    information or content You have submitted to the{" "}
                    <span>InstaPost</span> Service.
                  </li>
                  <li>
                    You may provide comments, recommendations or suggestions
                    pertinent to the <span>InstaPost</span> Service that are
                    constructive, and You agree that <span>InstaPost</span> will
                    be able to freely use, modify and incorporate such
                    suggestions to propose the future improvement of this Terms
                    of use without imposing any obligation on You.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
