import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const Sidebar = () => {

    function UnSelect(){
        document.querySelector('.DesignMaker').setAttribute('style',"display:none")
        
    }

    function DesignLinks(){
        document.querySelector('.DesignMaker').setAttribute('style',"display:flex !important")
        
    }
    return (
        <nav className="DashboardNav">
                <NavLink onClick={UnSelect}  to="/Dashboard/ManageAccounts" activeClassName="ActiveLink" >Manage Accounts</NavLink>
                <NavLink onClick={UnSelect} to="/Dashboard/ManageSocialMedia" activeClassName="ActiveLink">Manage Social Media</NavLink>
                <button  onClick={DesignLinks} className="DesignBtn">Design Editor</button>
                <div className="DesignMaker">
                    <NavLink activeClassName="ActiveLinkDesigner" to="/Dashboard/DesignMaker">Create New</NavLink>
                    <NavLink to="/Dashboard/SavedDesigns" activeClassName="ActiveLinkDesigner">Saved Designs</NavLink>
                </div>
        </nav>
    )
}

export default Sidebar
