import React from 'react'
import { IoLogoTiktok } from 'react-icons/io5';
import { useHistory } from 'react-router';

const AddNewAccount = () => {
    const History = useHistory();
    function GoBack(){
        History.push('/Dashboard/ManageAccounts');
    }
    return (
        <div className="AddNewAccountContainer">
            <div className="AddNewAccountBanner">
                <button onClick={GoBack}><img src="../../GoBack.svg" alt="" /></button>
                <div className="AddNewAccountHeader">
                    <div className="PlusAddNewAccount">
                        <img src="../../Plus.svg" alt="" />
                        <h1>Add New Account</h1>
                    </div>
                    <span>Please proceed with given below option to get started</span>
                </div>
            </div>
            <div className="AccountTypes">
                <button><i className="fab fa-facebook-square"></i> Continue with Facebook</button>
                <button><i className="fab fa-instagram"></i> Continue with Instagram</button>
                <button><i className="fab fa-twitter"></i> Continue with Twitter</button>
                <button><img src="../../Tiktok.svg" alt="" />  Continue with TikTok</button>
            </div>
        </div>
    )
}

export default AddNewAccount
