import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  function RedirectToAuth() {
    history.push("/ComingSoon");
  }

  return (
    <div className="main-container">
      <section className="HeroSection">
        <div className="Introduction">
          <h1>Want To Access All Your Social Media Account From One Place?</h1>
          <h3>
            Instapost will help you bring all your account together and help you
            manage your account’s analytics
          </h3>
          <div className="IntroductionBtns">
            <button className="Googlebtn" onClick={RedirectToAuth}>
              Continue with google
            </button>
            <button className="Infobtn">More Information</button>
          </div>
        </div>
        <div className="Img">
          <img src="Social tree-amico 1.svg" alt="" />
        </div>
      </section>
      <section className="Services" id="Services">
        <h1>Services we offer</h1>
        <div className="banner">
          <div className="BannerImg">
            <img src="Banner1.png" alt="" />
          </div>
          <div className="BannerDetails">
            <h1>Connect to your social medias in one place</h1>
            <p>
              Connect multiple social media accounts easily for free. Get the
              fun and enjoy at the only and combined platform
            </p>
          </div>
        </div>
        <div className="banner">
          <div className="BannerDetails Banner2Detail">
            <h1>Watch as your account grow</h1>
            <p className="Banner2Details">
              Control your analytics and insights on your account’s performance
              with easy to use interface{" "}
            </p>
          </div>
          <div className="BannerImg Banner2Img">
            <img src="Banner2.png" alt="" />
          </div>
        </div>
      </section>
      <section className="DesignEditor">
        <img src="freepik--Tree--inject-2.svg" alt="" />
        <div>
          <h1>Try Out All Social Platform Meme Marker For Free At One Place</h1>
          <p>
            Control your analytics and insights on your account’s performance
            with easy to use interface
          </p>
          <button
            className="Designbtn"
            onClick={() => {
              history.push("/ComingSoon");
            }}
          >
            Try Now!
          </button>
        </div>
      </section>
    </div>
  );
};

export default Home;
