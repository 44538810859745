import { useEffect,useState } from 'react'
import { useHistory } from 'react-router';

const DesignMaker = ({SetDesignerImg}) => {
    const History = useHistory();
    
    const [Imgs,SetImgs] = useState([]);
    
    useEffect(()=>{
        if(window.innerWidth <= 540){
            document.querySelector(".DesignMaker").setAttribute('style',"display:none");
        }
        const LoadImgs = async () =>{
            const res = await fetch('https://api.imgflip.com/get_memes')
            const data = await res.json()
            const Images = await data.data.memes
            SetImgs(Images)
        }
        LoadImgs();
    },[]);
   

    return (
        <div>
            <h1 className="DesignEditorHeader">Design Editor</h1>
            <div className="DesignerCreateNew">
                <div class="SelectTemplate">
                    <span>Select your template:</span>
                    <span>and then create new</span>
                </div>
                <button onClick={()=>{ History.push('/DesignEditor');}}>Create New</button>
            </div>
            
            <div className="DesignerTemplates">
                         {
                             Imgs.map((img) =>{
                               return <img key={img.id} src={img.url} alt="" width={img.width / 5} height={img.height / 5} onClick={()=>{ SetDesignerImg(img.url);History.push('/DesignEditor');}}/>
                             })
                         }
            </div>
        </div>
    )
}

export default DesignMaker
