const Home = () => {
  return (
    <div className="main-container">
      <section className="HeroSection">
        <div className="Introduction">
          <h1>Privacy Policy</h1>
        </div>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="tos_content">
                <ol>
                  <li>
                    <span>InstaPost</span> collects information about You, for
                    the sole purpose of authorizing and supporting the service
                    provision when You happen to be under these circumstances:
                    register or sign up to the system of <span>InstaPost</span>{" "}
                    Service, submit the information as part of the identity
                    verification process, perform transactions, request receipt
                    either in the printed or digital version, as well as when
                    You upload or post information related to your products and
                    services (including inventory, pricing, and other data).{" "}
                    <span>InstaPost</span> may collect, but are not restricted
                    to collect the following data: basic or general information
                    from users encompassing your name, email address, business
                    address, telephone number, and date of birth.
                  </li>
                  <li>
                    <span>InstaPost</span> can automatically record certain
                    information extracted from your system by using different
                    types of tracking technology. This "auto-collected"
                    information may include Internet Protocol Addresses ("IP
                    Address"), unique devices or user IDs (User ID), installed
                    software versions, system type, date, time and details of
                    your transactions, as well as information from your account
                    provided publicly by You.
                  </li>
                  <li>
                    <span>InstaPost</span> will not sell, rent or otherwise
                    disclose your email addresses or your personal information
                    or data from your customers to other parties (except where
                    required by or under the force of applicable law).{" "}
                    <span>InstaPost</span> will only use such information to
                    provide, maintain and enhance <span>InstaPost</span>{" "}
                    Services and to transmit information and support your
                    request, including receipts, reminders, and support messages
                    as well as administrative messages. Data collected by{" "}
                    <span>InstaPost</span> are used for the purpose of delivery
                    of news and information concerning <span>InstaPost</span>{" "}
                    Services and to communicate about products, promotions and
                    updates to You offered by <span>InstaPost</span> and
                    selected partners or parties designated by{" "}
                    <span>InstaPost</span>.
                  </li>
                  <li>
                    Your transaction history and location may be used as
                    aggregate and non-personal information to generate general
                    statistics to conduct analysis of the buying behavior of the
                    population to which You are included as part of the
                    population, in order to provide a better service.{" "}
                    <span>InstaPost</span> may also use information about You to
                    verify your identity, to investigate and prevent fraud or
                    other illegal activities, and for any other purposes that
                    will be disclosed to You in connection with the{" "}
                    <span>InstaPost</span> service.
                  </li>
                  <li>
                    <span>InstaPost</span> may disclose the information it
                    collects, in which <span>InstaPost</span> is requested or
                    required to do so by applicable law, by law enforcement
                    agencies or other governmental or public institutions, for
                    example, in response to a court order. To the extent
                    permitted by applicable law, <span>InstaPost</span> may also
                    disclose information in response to requests by law
                    enforcement agencies or other governmental or public
                    institutions or if <span>InstaPost</span> considers that
                    such disclosure may prevent the occurrence of criminal acts,
                    facilitate investigations relating to public security,
                    protect the security or integrity of the{" "}
                    <span>InstaPost</span> Services, or allow{" "}
                    <span>InstaPost</span> to take preventive action against
                    potential loss.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
