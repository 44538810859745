import React from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineSend }  from 'react-icons/ai';

const ShowImg = ({SelectedImg}) => {
    const History = useHistory();
    if(!SelectedImg){
        History.push('/Dashboard/ManageSocialMedia');
    }
    function GoBack(){
        History.push('/Dashboard/ManageSocialMedia');
    }
    return (
        <div className="ShowImgSection">
            <button onClick={GoBack}><img src="../../GoBack.svg" alt="" /></button>
            <div className="ImgCommentSection">
                <div className="ImgSection"> 
                    <img src={"../"+SelectedImg} alt="" />
                    <p>Hey check out this picture</p>
                </div>
                <div className="CommentSection">
                    <div className="CommentHeader">
                        <h1>Comments</h1>
                        <span>See who has commented on your posts</span>
                    </div>
                    <div className="Comment">
                        <img src="../../logo192.png" alt="" />
                        <p>This is a comment</p>
                        <div className="TimeLikeReply">
                            <span className="Time">1min</span> 
                            <span className="LikeReply">Like Reply</span>
                        </div>
                        <img src="../../logo192.png" alt="" />
                        <p>This is a comment</p>
                        <div className="TimeLikeReply">
                            <span className="Time">1min</span> 
                            <span className="LikeReply">Like Reply</span>
                        </div>
                        <img src="../../logo192.png" alt="" />
                        <p>This is a comment</p>
                        <div className="TimeLikeReply">
                            <span className="Time">1min</span> 
                            <span className="LikeReply">Like Reply</span>
                        </div>
                        <img src="../../logo192.png" alt="" />
                        <p>This is a comment</p>
                        <div className="TimeLikeReply">
                            <span className="Time">1min</span> 
                            <span className="LikeReply">Like Reply</span>
                        </div>
                        <img src="../../logo192.png" alt="" />
                        <p>This is a comment</p>
                        <div className="TimeLikeReply">
                            <span className="Time">1min</span> 
                            <span className="LikeReply">Like Reply</span>
                        </div>
                    </div>
                    <hr />
                    <div className="UserCommentSection">
                        
                        <input type="text" className="UserComment" placeholder="Write a comment "/>
                        <div className="UserCommentEmoji">
                            <img src="../../SmileEmoji.svg" alt="" />
                            <img src="../../Gif.svg" alt="" />
                            <img src="../../Sticker.svg" alt="" />
                        </div>
                        <button>Send</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowImg
