import React from 'react';
import { useHistory } from 'react-router';


const DesignEditor = ({DesignerImg}) => {
    const History = useHistory()

        function GoBack(){
            History.push('/Dashboard/DesignMaker')
        }
    
    return (
        
        
        

        <div className="DesignEditorCanvasArea">
            <h1 className="DesignEditorHeader">Design Editor</h1>
            <div className="DesignEditorBanner">
                <button onClick={GoBack}><img src="GoBack.svg" alt="" /></button>
                <div>
                    <button>Save Design</button>
                    <button><img src="../PhotoVideo.svg" alt="" /> Add Image</button>
                    <button>Add Text</button>
                </div>
            </div>
            <div className="Designer">
            <div className="DesignerButtons">
            <button><img src="../undo.svg" alt="" />
</button>
                <button><img src="../redo.svg" alt="" /></button>
   
                <button><img src="../textfont.svg" alt="" />
</button>
                <button><img src="../textcolor.svg" alt="" />
</button>
                <button><img src="../fontsize.svg" alt="" />
</button>
                <button><img src="../textstyle.svg" alt="" />
</button>
                <button><img src="../bold.svg" alt="" />
</button>
                <button><img src="../italic.svg" alt="" />
</button>
                <button><img src="../underline.svg" alt="" />
</button>
                <button><img src="../leftalignment.svg" alt="" />
</button>
                <button><img src="../centeralignment.svg" alt="" />
</button>
                <button><img src="../rightalignment.svg" alt="" />
</button>
                <button><img src="../bucket.svg" alt="" />
</button>
                <button><img src="../stroke.svg" alt="" />
</button>
          </div>
            <canvas></canvas>
            </div>
        </div>
    )
}

export default DesignEditor
