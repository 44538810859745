import '../Dashboard.css';
import Sidebar from './Dashboard/Sidebar';
import ManageAccount from './Dashboard/ManageAccount';
import ShowImg from './Dashboard/ShowImg';

import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import ManageSocialAccounts from './Dashboard/ManageSocialAccounts';
import { useState } from 'react';
import CreatePost from './Dashboard/CreatePost';
import AddNewAccount from './Dashboard/AddNewAccount';
import DesignMaker from './Dashboard/DesignMaker';
import ShowSavedDesigns from './Dashboard/ShowSavedDesigns';
import SavedDesigns from './Dashboard/SavedDesigns';

const Dashboard = ({ SetDesignerImg ,DesignerImg}) => {
    const [SelectedImg,SetSelectedImg] = useState(null)
    const [SavedDesign,SetSavedDesign] = useState(null);
   
    return (
        
            <div className="Container">
                <Sidebar></Sidebar>
                <section className="MainDashboard">
                
                    <Switch>
                        <Route path="/Dashboard/ManageAccounts" exact>
                            <ManageAccount></ManageAccount>
                        </Route>
                        <Route path="/Dashboard/ManageSocialMedia" exact>
                            <ManageSocialAccounts SetSelectedImg = {SetSelectedImg} ></ManageSocialAccounts>
                        </Route>
                        <Route path="/Dashboard/DesignMaker" exact>
                            <DesignMaker SetDesignerImg={SetDesignerImg}></DesignMaker>
                        </Route>
                        <Route path="/Dashboard/ManageSocialMedia/ShowImg">
                            <ShowImg SelectedImg = {SelectedImg}></ShowImg>
                        </Route>
                        <Route path="/Dashboard/ManageSocialMedia/CreatePost">
                            <CreatePost></CreatePost>
                        </Route>
                        <Route path="/Dashboard/ManageAccounts/AddNewAccount">
                            <AddNewAccount></AddNewAccount>
                        </Route>
                        <Route path="/Dashboard/SavedDesigns" exact> 
                            <SavedDesigns SetSavedDesign={SetSavedDesign}></SavedDesigns>
                        </Route>
                        <Route path="/Dashboard/SavedDesigns/ShowSavedDesigns">
                            <ShowSavedDesigns SavedDesign={SavedDesign}></ShowSavedDesigns>
                        </Route>
                        
                        {/* <Route path="/DesignEditor">
                            <DesignEditor DesignerImg={DesignerImg}></DesignEditor>
                        </Route> */}
                    </Switch>
                
            </section>
            </div>
       
    )
}

export default Dashboard
