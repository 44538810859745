import { useHistory } from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SiTiktok } from 'react-icons/si';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    select : {
        background: '#FFFFFF',
        border: '1px solid #5A54E5',
        borderRadius: '5px',
        padding: '0.5rem 0.2rem !important',
        fontWeight: 'normal',
        width: '110%',
        height: '77%',
        color: '#5A54E5',
        background: 'transparent',
        display: 'flex',
        gap: '1rem'
    },
    menuItems : {
        backgroundColor: 'transparent !important',
        display: 'flex',
        gap: '1rem',
        color: '#5A54E5',
        padding:'1rem'
    }
}))

const ManageSocialAccounts = ({SetSelectedImg}) => {
    const classes = useStyles();
    const [value,SetValue] = useState('Facebook');
    const Userhistory = useHistory();
    function CreatePost(){
        Userhistory.push('/Dashboard/ManageSocialMedia/CreatePost');
    }
    function SelectList(event){
        SetValue(event.target.value);
    }
    return (
        <div className="ManageSocialMedia">
            <h1 className="ManageSocialAccountsHeader">Manage Social Media</h1>
            <div className="UserHeader">
                <div className="UserSelector">
                    <span>Select Account</span>
                    {/* <select name="" id="">
                        <option value="" ><Icon class="fab fa-facebook-square"></Icon>   Facebook User</option>
                        <option value="" >Instagram User</option>
                        <option value="">Twitter User</option>
                        <option value="" >TikTok User</option>
                    </select> */}
                    <FormControl  className="UserSelector">
                        <Select labelId="label" id="select" value={value} className={classes.select} onChange={SelectList}>
                            <MenuItem value="Facebook" className={classes.menuItems}><Icon className="fab fa-facebook-f" ></Icon> Facebook User</MenuItem>
                            <MenuItem value="Instagram" className={classes.menuItems}><Icon className="fab fa-instagram"></Icon> Instagram User</MenuItem>
                            <MenuItem value="Twitter" className={classes.menuItems}><Icon className="fab fa-twitter"></Icon> Twitter User</MenuItem>
                            <MenuItem value="TikTok" className={classes.menuItems}><Icon><SiTiktok></SiTiktok></Icon> TicTok User</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                    <button className="CreatePostbtn" onClick={CreatePost}>Create Post</button>
            </div>
            <hr />
            <div className="ViewPostSection">
                <img src="../logo192.png" alt="" width="100" height="100" onClick={()=>{SetSelectedImg("../logo192.png"); Userhistory.push("/Dashboard/ManageSocialMedia/ShowImg")}}/>
                <img src="../Banner1.png" alt="" width="100" height="100" onClick={()=>{SetSelectedImg("../Banner1.png"); Userhistory.push("/Dashboard/ManageSocialMedia/ShowImg")}}/>
                <img src="../Banner2.png" alt="" width="100" height="100" onClick={()=>{SetSelectedImg("../Banner2.png"); Userhistory.push("/Dashboard/ManageSocialMedia/ShowImg")}}/>
                <img src="../logo512.png" alt="" width="100" height="100" onClick={()=>{SetSelectedImg("../logo512.png"); Userhistory.push("/Dashboard/ManageSocialMedia/ShowImg")}}/>
            </div>
        </div>
    )
}

export default ManageSocialAccounts
