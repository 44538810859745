import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { useState } from "react";
import DesignEditor from "./components/DesignEditor";
import ComingSoon from "./components/ComingSoon";
import Privacy from "./components/Privacy";
import Term from "./components/Term";

function App() {
  const [DesignerImg, SetDesignerImg] = useState(null);
  return (
    <Router>
      <div className="App">
        <Header></Header>

        <Switch>
          <Route path="/" exact>
            <Home></Home>
          </Route>
          <Route path="/Dashboard">
            <Dashboard
              SetDesignerImg={SetDesignerImg}
              DesignerImg={DesignerImg}
            ></Dashboard>
          </Route>
          <Route path="/DesignEditor" component={DesignEditor}></Route>
          <Route path="/ComingSoon" component={ComingSoon}></Route>
          <Privacy path="/Privacy" component={Privacy}></Privacy>
          <Term path="/Term" component={Term}></Term>
        </Switch>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
