import React from 'react';
import { useHistory } from 'react-router';
const SavedDesigns = ({SetSavedDesign}) => {

    const History = useHistory()
    if(window.innerWidth <= 540){
        document.querySelector(".DesignMaker").setAttribute('style',"display:none");
    }
    function GoBack(){
        History.push('/Dashboard/DesignMaker')
    }


    return (
        <div className="ManageSocialMedia">
            <h1 className="ManageSocialAccountsHeader">Design Editor</h1>
            <div className="DesignerCreateNew">
                <div className="SelectTemplate">
                    <span>All your favourite design</span>
                    <span>Create and save more</span>
                </div>
                <button onClick={()=>{ History.push('/DesignEditor');}}>Create New</button>
            </div>
            <hr />
            <div className="ViewPostSection">
                <img src="../logo192.png" alt="" width="100" height="100" onClick={()=>{SetSavedDesign("../logo192.png"); History.push("/Dashboard/SavedDesigns/ShowSavedDesigns")}}/>
                <img src="../Banner1.png" alt="" width="100" height="100" onClick={()=>{SetSavedDesign("../Banner1.png"); History.push("/Dashboard/SavedDesigns/ShowSavedDesigns")}}/>
                <img src="../Banner2.png" alt="" width="100" height="100" onClick={()=>{SetSavedDesign("../Banner2.png"); History.push("/Dashboard/SavedDesigns/ShowSavedDesigns")}}/>
                <img src="../logo512.png" alt="" width="100" height="100" onClick={()=>{SetSavedDesign("../logo512.png"); History.push("/Dashboard/SavedDesigns/ShowSavedDesigns")}}/>
            </div>
        </div>
    )
}

export default SavedDesigns
