import React from 'react'
import { useHistory } from 'react-router'

const ShowSavedDesigns = ({SavedDesign}) => {
    const History = useHistory();
    if(!SavedDesign){
        History.push('/Dashboard/SavedDesigns')
    }
    return (
        <div className="SavedDesignView"> 
            <div className="ShowSavedDesigns">
                <div>
                    <img src={"../" + SavedDesign} alt="" />
                </div>
                <div>
                    <button>Download</button>
                    <button>Share</button>
                    <button>Edit</button>
                </div>
            </div>
        </div>
    )
}

export default ShowSavedDesigns
