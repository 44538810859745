import React from 'react'
import { useHistory } from 'react-router'

const ManageAccount = () => {
    
    const History = useHistory();

    function AddNewAccount(){
        History.push('/Dashboard/ManageAccounts/AddNewAccount')
    }

    return (
        <div className="ManageAccountsDB">
            <div className="ManageAccountHeader">
                <button onClick={AddNewAccount}> <img src="../Group 2.svg" alt="" /> Add New Account</button>
                <span>Account Management Panel</span>
            </div>
            <table className="AccountsTable" cellSpacing="0">
                <tr>
                    <th>ID</th>
                    <th>Added On</th>
                    <th>Account Type</th>
                    <th>Action</th>
                </tr>
                <tr>
                    <td>User@gmail.com</td>
                    <td>28-08-2021</td>
                    <td> <img src="../facebook.svg" alt="" />  Facebook</td>
                    <td><button>Delete</button></td>
                </tr>
                <tr>
                    <td>User@gmail.com</td>
                    <td>28-08-2021</td>
                    <td>  <img src="../instagram.svg" alt="" />  Instagram</td>
                    <td><button>Delete</button></td>
                </tr>
                <tr>
                    <td>User@gmail.com</td>
                    <td>28-08-2021</td>
                    <td> <img src="../twitter.svg" alt="" /> Twitter</td>
                    <td><button>Delete</button></td>
                </tr>
                <tr>
                    <td>User@gmail.com</td>
                    <td>28-08-2021</td>
                    <td> <img src="../TiKtok.svg" alt="" /> TikTok</td>
                    <td><button>Delete</button></td>
                </tr>
            </table>
        </div>
    )
}

export default ManageAccount
