import React from 'react'
import { useHistory } from 'react-router';

const CreatePost = () => {
    const History = useHistory();
    function GoBack(){
        History.push('/Dashboard/ManageSocialMedia');
    }
    return (
        <div className="CreatePostSection">
            <button onClick={GoBack}><img src="../../GoBack.svg" alt="" /></button>
            <div className="PostSection">
                
                <div className="CreatePostHeadings">
                    <div>
                        <h3>Create Post</h3>
                        <p>Lets share your thoughts or your snaps , memories and videos</p>
                    </div>
                    <p>What’s on your mind....</p>
                    <input type="text" placeholder="What's on your mind..." id="CreatePost"/>
                    <div className="PostBtnsection">
                        <button className="PhotoVideo"><img src="../../PhotoVideo.svg" alt="" /> Photo/Video</button>
                        <div>
                            <button className="Postbtn">Post</button>
                            <button className="CancelBtn" onClick={GoBack}>Cancel</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default CreatePost
